<div class="table-sticky-wrapper">
    <table mat-table class="full-width-table" matSort aria-label="Elements" [dataSource]="dataSource" matSort [ngClass]="{
        'hide-headers': hideHeaders
    }">

        <ng-container *ngFor="let column of columns" matColumnDef="{{column.name}}" [stickyEnd]="column.sticky==='end'">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [ngStyle]="{'text-align': column.align, width: column.width}"
            >{{column.title}}</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="{'text-align': column.align}">
                <ng-container *ngIf="column.type==='text'||column.type===undefined">
                    {{column.valueFn(row) || row[column.name]  || null}}
                </ng-container>
                <ng-container *ngIf="column.type==='menu'">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{row:row}" (click)="clickMenu(row)">
                        <mat-icon aria-hidden="false">more_vert</mat-icon>
                    </button>
                </ng-container>
                <ng-container *ngIf="column.type==='button'">
                    <button mat-icon-button color="primary" >
                        <mat-icon aria-hidden="false" (click)="column.click(row)">{{ column.icon }}</mat-icon>
                    </button>
                </ng-container>
                <ng-container *ngIf="column.type==='checkbox'">
                    <mat-checkbox
                        (change)="onRowChecked($event, row)"
                    ></mat-checkbox>
                </ng-container>
                <ng-container *ngIf="column.type==='input'">
                    <mat-form-field class="full-width">
                        <input matInput [(ngModel)]="row[column.name]" [required]="column.required">
                        <mat-error class="error">
                            Por favor, complete el campo
                        </mat-error>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="column.type==='link'">
                    <a [routerLink]="column.routerLinkFn(row)">{{column.valueFn(row)}}</a   >
                </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToShow"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToShow;"></tr>
    </table>
    </div>

<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-aliasMenuItems="row">
        <button *ngFor="let menuItem of menuItems" mat-menu-item (click)="menuItem.click(aliasMenuItems)">{{ menuItem.label}}</button>
    </ng-template>
</mat-menu>
