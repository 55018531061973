import { Component } from '@angular/core';
import { MenuBaseComponent } from '../menu-base.component';

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss']
})

export class MenuMobileComponent extends MenuBaseComponent {


}
