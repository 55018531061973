<a href="{{parametros?.Facebook}}" target="_blank">
    <img class="imagen" src="assets/images/facebook.png">
</a>

<a href="{{parametros?.Instagram}}" target="_blank">
    <img  class="imagen" src="assets/images/instagram.png">
</a>

<a href="{{parametros?.Linkedin}}" target="_blank">
    <img class="imagen" src="assets/images/linkedin.png">
</a>