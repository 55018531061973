<app-layout-general>
    <contenido fxLayout.gt-md="row" fxLayoutAlign="center center">
        <mat-card *ngIf="tyc">
            <mat-card-title style="font-size: 20px;">Términos y condiciones</mat-card-title>	
            <mat-card-content [innerHtml]="tyc">
                
            </mat-card-content>
        </mat-card>
    </contenido>
</app-layout-general>
